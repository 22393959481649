var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "margin-top": "24px"
    }
  }, [_c('MealScheduleContainer', {
    attrs: {
      "is-edited": _vm.isEdited,
      "data": _vm.data,
      "is-program-consultation-basic": _vm.isProgramConsultationBasic,
      "tabs": _vm._tabs,
      "sub-tabs": _vm._subTabs
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }